import { EngineStore } from '~/stores/engine'
import { SessionStore } from '~/stores/session'

export type PushToSaveScenarios = 'add-needs' | 'saved-plan' //more coming

export default class PushToSave {
  static use(scenario: PushToSaveScenarios, dialogId: string = 'push-to-save') {
    const { show } = DialogController.use()
    const { flag } = FeatureFlags.use()
    const session = SessionStore.use()
    const { track } = Analytics.use()

    const { isAuthenticated } = Auth.use()

    const _scenario = computed(() => _camelCase(scenario))

    const enabled = computed(() => flag<boolean>(`push-to-save-${scenario}`).value)

    const maybePushToSave = () => {
      if(!isAuthenticated.value && enabled.value && (!_has(session.pushedToSave, _scenario.value) || !session.pushedToSave[_scenario.value])) {
        show(dialogId)
        session.pushedToSave = { ...session.pushedToSave, [_scenario.value]: true }
        track('PushToSaveShown', {
          scenario: _scenario.value,
        })
      }
    }

    return {
      maybePushToSave
    }
  }
}