<template>
  <HpToolTip log :target-text="text" :title="usedContent?.title ?? undefined">
    <span v-html="usedContent?.body" />
  </HpToolTip>
</template>

<script lang="ts" setup>
  const props = defineProps<{
    text: string | null
    name?: string | null | undefined
    title?: string | null | undefined
    body?: string | null | undefined
  }>()

  const { getTooltipContent } = Cms.use()

  const usedContent = ref(
    !_isNil(props.name)
      ? (await getTooltipContent(props.name)).content.value
      : { title: props.title, body: props.body }
  )

  watch(
    () => props.name,
    async (newVal, oldVal) => {
      if (!!newVal && newVal !== oldVal) {
        usedContent.value = (await getTooltipContent(newVal)).content.value
      }
    }
  )
</script>

<style scoped>
  :deep(.tooltip-body) {
    & a {
      @apply text-white;
    }

    /* This gives the illusion of bold text on hover, without the layout shift that happens when changing to a bold font  */
    & a:hover {
      display: inline-block;
      text-shadow: 0.35px 0.15px 0.5px white;
      transform: scale(1.005);
    }
  }
</style>
